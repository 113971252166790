<script>
import offlineOrder from './offlineOrder/index.vue'
import invalidManage from './invalidManage/index.vue'
import clueDefeat from './clueDefeat/index.vue'
import leadChangeInto from './leadChangeInto/index.vue'
import orderEdit from './orderEdit/index.vue'
import testDrive from './testDrive/index.vue'
import {
  reviewCenterCount,
  reviewCenterCountForProtal,
} from '@/services/approveManage'
import SearchBarNew from '@/components/search-bar-new'
import FilterPanel from '@/components/filter-panel'
import baseDataServices from '@/services/baseDataServices'
import invalidManageServices from '@/services/invalidManageServices.js'
import OptionsBox from '@/components/OptionsBox'
import salesAssistantService from '@/services/salesAssistant'
import { v4 as uuidv4 } from 'uuid'
export default {
  name: 'ApproveManage',
  components: {
    offlineOrder,
    invalidManage,
    clueDefeat,
    leadChangeInto,
    orderEdit,
    testDrive,
    SearchBarNew,
    FilterPanel,
    OptionsBox,
    ClueReassignment: () =>
      import('@/modules/approveManage/clueReassignment/index.vue'),
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$store.commit('app/addKeepAlive', 'ApproveManage')
    })
  },
  beforeRouteLeave(to, from, next) {
    // 如果回到销售助手（店端/总部）、交付助手首页 清空过滤数据
    if (['/delivery-assistant','/salesAssistant','/sales-assistant-portal'].some((item) => { return to.path.includes(item) })) {
      this.$store.commit('app/deleteKeepAlive', 'ApproveManage')
    }
    next()
  },
  data() {
    return {
      searchActive: false,
      searchStr: '',
      filterPanelVisible: false,
      filterOptions: [],
      commonFilterParams: {
        queryType: 0,
        searchStr: '',
        applyTimeEnd: '',
        applyTimeStart: '',
        reviewStatuses: [],
        reviewTimeEnd: '',
        reviewTimeStart: '',
        transferType: null,
      },
      type: '',
      countInfo: {},
    }
  },
  computed: {
    tabs() {
      const { type, roleLogos = [] } = this.$store.getters.userInfo
      if (type === 1) {
        // 店端
        if (roleLogos.includes('1014003')) { // 店长
          return [
            {
              name: '线索无效',
              value: 0,
              componentName: 'invalidManage',
              countKey: 'invalidNum',
            },
            {
              name: '线索战败',
              value: 1,
              componentName: 'clueDefeat',
              countKey: 'defeatNum',
            },
            {
              name: this.$t('线索转入'),
              value: 2,
              componentName: 'leadChangeInto',
              countKey: 'dealerTransferCount',
            },
            {
              name: this.$t('上门试驾'),
              value: 4,
              componentName: 'testDrive',
              countKey: 'inTestDrive',
            },
            {
              name: this.$t('购车相关协议'),
              value: 5,
              componentName: 'offlineOrder',
              countKey: 'scrmOrderNum',
            },
            {
              name: this.$t('异常订单'),
              value: 3,
              componentName: 'orderEdit',
              countKey: 'orderNum',
            },
          ]
        } else if (roleLogos.includes('1014004')) { // master
          return [
            {
              name: '线索无效',
              value: 0,
              componentName: 'invalidManage',
              countKey: 'invalidNum',
            },
            {
              name: '线索战败',
              value: 1,
              componentName: 'clueDefeat',
              countKey: 'defeatNum',
            },
            {
              name: this.$t('购车相关协议'),
              value: 5,
              componentName: 'offlineOrder',
              countKey: 'scrmOrderNum',
            },
            {
              name: this.$t('上门试驾'),
              value: 4,
              componentName: 'testDrive',
              countKey: 'inTestDrive',
            },
          ]
        }
        return []
      }
      let tabArr = [
        {
          name: '线索无效',
          value: 0,
          componentName: 'invalidManage',
          countKey: 'invalidNum',
        },
        {
          name: '线索战败',
          value: 1,
          componentName: 'clueDefeat',
          countKey: 'defeatNum',
        },
        {
          name: this.$t('线索转入'),
          value: 2,
          componentName: 'leadChangeInto',
          countKey: 'dealerTransferCount',
        },
        {
          name: this.$t('购车相关协议'),
          value: 5,
          componentName: 'offlineOrder',
          countKey: 'scrmOrderNum',
        },
        {
          name: this.$t('中央客服改派线索'),
          value: 6,
          componentName: 'ClueReassignment',
          countKey: 'headDealerTransfer',
        },
        {
          name: this.$t('异常订单'),
          value: 3,
          componentName: 'orderEdit',
          countKey: 'orderNum',
        },
      ]
      // 1014030-城市经理 1014021-区域经理 线索转入
      // 1014010-客服经理 线索无效、线索战败、中央客户改派线索
      // 1014050-SO 1014051-财务 1014052财务经理 异常订单处理
      // 线下下单
      if (!roleLogos.includes('1014010')) {
        tabArr = tabArr.filter(({ value }) => ![0,1,6].includes(value))
      }
      if (!roleLogos.some((cdoe) => ['1014030', '1014021'].includes(cdoe))) {
        tabArr = tabArr.filter(({ value }) => ![2].includes(value))
      }
      if (!roleLogos.some((cdoe) => ['1014030', '1014021', '1014050', '1014051'].includes(cdoe))) {
        tabArr = tabArr.filter(({ value }) => ![3].includes(value))
      }
      // 厂端 城市经理 才能做购车相关协议审批
      if (!roleLogos.some((cdoe) => ['1014030'].includes(cdoe))) {
        tabArr = tabArr.filter(({ value }) => ![5].includes(value))
      }
      return tabArr
    },
  },
  watch: {
    '$store.getters.userInfo': {
      immediate: true,
      handler(val) {
        if (val && val.id) {
          this.reviewCenterCount()
        }
      },
    },
  },
  mounted() {
    const { typeInfo='' } = this.$route.query
    if (typeInfo) this.type = typeInfo
  },
  activated() {
    this.reviewCenterCount()
  },
  methods: {
    getRefundReasonItem() {
      const a = [] ,b = []
      this.$store.getters.dictHash[1038].forEach(({ code, descr,name })=>{
        if (descr == 1) {
          a.push({ id: code, name, value: code }) 
        } else {
          b.push({ id: code, name, value: code })
        }
      })
      return {
        id: uuidv4(),
        type: 'commonCascader',
        label: this.$t('退单原因'),
        field: 'essentialRefundReasons',
        tagsInput: true,
        valueType: 'checkedValues',
        options: [
          {
            id: uuidv4(),
            name: this.$t('实质性退单'),
            value: 1,
            children: a
          },  
          {
            id: uuidv4(),
            name: this.$t('非实质性退单'),
            value: 0,
            children: b
          },  
        ]
      }
    },
    async getFailItem() {
      // const failCodesList = await baseDataServices.defeatClueTree()
      const failCodesList = await baseDataServices.defeatClueTreeNew({ bizType: this.$store.getters.userInfo.type === 1 ? 1 : 2 })
      return {
        id: uuidv4(),
        type: 'commonCascader',
        label: this.$t('战败原因'),
        field: 'failCodes',
        tagsInput: true,
        valueType: 'checkedValues',
        options: failCodesList.map((item) => {
          return {
            id: uuidv4(),
            name: item.reason,
            value: item.id,
            children:
              item.children && item.children.length
                ? item.children.map((item1) => {
                  return {
                    id: uuidv4(),
                    name: item1.reason,
                    value: item1.id,
                    children:
                      item1.children && item1.children.length
                        ? item1.children.map((item2) => {
                          return {
                            id: uuidv4(),
                            name: item2.reason,
                            value: item2.id,
                            children: [],
                          }
                        })
                        : [],
                  }
                })
                : [],
          }
        }),
      }
    },
    async getInvalidItem() {
      // 无效原因
      const failCodesList = await invalidManageServices.getReasonList({})
      console.log('failCodesList:', failCodesList)
      return {
        id: uuidv4(),
        type: 'options',
        label: this.$t('无效原因'),
        multiple: true,
        field: 'failCodes',
        options: failCodesList.map((item) => {
          return {
            id: uuidv4(),
            label: item.reason,
            value: item.id,
          }
        }),
      }
    },
    async createFilterOptions() {
      const arr = []
      if (this.$store.getters.userInfo.type === 0 && this.type === 'orderEdit') {
        const { parentCode='',
          childCode='',
          dealerProvinceCode='',
          dealerCityCode='',
          dealerIds=''
        } = this.$route.query
        const parentList = await salesAssistantService.getRegionTreeApi()
        // 大区
        arr.push({
          id: uuidv4(),
          type: 'selectOptions',
          label: this.$t('大区'),
          field: 'parentCode',
          defaultValue: parentCode,
          options: [],
          options: parentList.map(({ code, name, children=[] }) => ({ id: code, code, name, children })),
        })
        // 城市区域
        arr.push({
          id: uuidv4(),
          type: 'selectOptions',
          label: this.$t('城市区域'),
          multiple: true,
          field: 'childCode',
          defaultValue: childCode,
          options: [],
        })
        const provinceList = await salesAssistantService.getPronceApi({ parentId: 0 })
        // 省份
        arr.push({
          id: uuidv4(),
          type: 'selectOptions',
          label: this.$t('门店省份'),
          field: 'dealerProvinceCode',
          defaultValue: dealerProvinceCode,
          options: provinceList.map(({ code, name }) => ({ id: code, code, name })),
        })
        // 城市
        arr.push({
          id: uuidv4(),
          type: 'selectOptions',
          label: this.$t('门店城市'),
          field: 'dealerCityCode',
          defaultValue: dealerCityCode,
          options: [],
        })
        // 交付门店
        const dealerResult = await salesAssistantService.getRegionPageApi({ childCode: [], cityCode: '', pageNum: 1, pageSize: 100000, parentCode: '', provinceCode: '' })
        arr.push({
          id: uuidv4(),
          type: 'selectOptions',
          label: this.$t('门店'),
          multiple: true,
          field: 'dealerIds',
          defaultValue: dealerIds,
          options: dealerResult.dataList.map((item) => { return { code: item.id, name: item.name } })
        })
      }
      arr.push({
        id: uuidv4(),
        type: 'dataRange',
        dateType: 'datetime',
        label: this.$t('申请时间'),
        start: {
          field: 'applyTimeStart',
        },
        end: {
          field: 'applyTimeEnd',
        },
      })
      if (this.commonFilterParams.queryType == 1) {
        let options = []
        if (['ClueReassignment', 'leadChangeInto'].includes(this.type)) {
          // 转入、指派
          this.$store.getters.getDictHash(1028).forEach((element) => {
            if (
              ['1028100', '1028000'].includes(element.code)
              // ||
              // (this.type == 'leadChangeInto' &&
              //   this.$store.getters.userInfo.type == 1 &&
              //   ['1028015', '1028005'].includes(element.code))
            ) {
              options.push({
                id: element.code,
                label: element.name,
                value: element.code,
              })
            }
          })
        } else if (['invalidManage', 'clueDefeat'].includes(this.type)) {
          // 无效、战败
          this.$store.getters.getDictHash(1013).forEach((element) => {
            if (element.code != 1013001) {
              options.push({
                id: element.code,
                label: element.name,
                value: element.code,
              })
            }
          })
        } else if (this.type == 'orderEdit') {
          // 订单
          const type = this.$store.getters.userInfo.type
          this.$store.getters.getDictHash(3001).forEach((element) => {
            if (!['3001001'].includes(element.code)) {
              options.push({
                id: element.code,
                label: element.name,
                value: element.code,
              })
            }
          })
        } else if (this.type == 'offlineOrder') {
          options.push(
            {
              id: 5,
              label: '已通过',
              value: 5,
            },
            {
              id: 10,
              label: '已驳回',
              value: 10,
            }
          )
        } else if (this.type == 'testDrive') {
          options.push(
            {
              id: 2,
              label: '通过',
              value: 2,
            },
            {
              id: 1,
              label: '驳回',
              value: 1,
            }
          )
        }
        arr.push(
          {
            id: uuidv4(),
            type: 'dataRange',
            dateType: 'datetime',
            label: this.$t('审批时间'),
            start: {
              field: 'reviewTimeStart',
            },
            end: {
              field: 'reviewTimeEnd',
            },
          },
          {
            id: uuidv4(),
            type: 'options',
            label: this.$t('审批状态'),
            multiple: true,
            field: 'reviewStatuses',
            options,
          }
        )
      }
      if (this.type == 'clueDefeat') {
        arr.splice(
          this.commonFilterParams.queryType == 0 ? 2 : 4,
          0,
          await this.getFailItem()
        )
      } else if (this.type == 'invalidManage') {
        arr.splice(
          this.commonFilterParams.queryType == 0 ? 1 : 3,
          0,
          await this.getInvalidItem()
        )
      }
      const { type, dealers } = this.$store.getters.userInfo
      if (
        type == 1 &&
        [
          'invalidManage',
          'clueDefeat',
          'orderEdit',
          'offlineOrder',
          'testDrive',
          // 'leadChangeInto',
        ].includes(this.type)
      ) {
        const res = await baseDataServices.getStaffList({
          roleLogo: '1014002,1014001',
          dealerId: dealers[0]?.id,
        })
        arr.push({
          id: uuidv4(),
          type: 'options',
          label: this.$t('销售顾问'),
          multiple: true,
          field: 'salesUserIds',
          options: res.map((element) => ({
            id: element.id,
            label: element.name,
            value: element.id,
          })),
        })
      } else if (
        type !== 1 &&
        ['clueDefeat', 'invalidManage'].includes(this.type)
      ) {
        const res = await baseDataServices.getStaffList({
          roleLogo: '1014008',
        })
        arr.push({
          id: uuidv4(),
          type: 'options',
          label: this.$t('中央客服'),
          multiple: true,
          field: 'salesUserIds',
          options: res.map((element) => ({
            id: element.id,
            label: element.name,
            value: element.id,
          })),
        })
      }
      if (this.type === 'orderEdit') {
        arr.push({
          id: uuidv4(),
          type: 'options',
          label: this.$t('申请类型'),
          field: 'type',
          options: [
            { id: uuidv4(), label: this.$t('订单变更'), value: 1 },
            { id: uuidv4(), label: this.$t('退单'), value: 3 },
            { id: uuidv4(), label: this.$t('同配置换车'), value: 2 }
          ],
        },
        {
          id: uuidv4(),
          type: 'options',
          label: this.$t('实质性退单'),
          field: 'essentialRefundStatus',
          options: [
            { id: uuidv4(), label: this.$t('是'), value: 1 },
            { id: uuidv4(), label: this.$t('否'), value: 0 },
           
          ],
        },
        this.getRefundReasonItem()
        )
      }
      if (['clueDefeat', 'invalidManage'].includes(this.type)){
        arr.push({
          id: uuidv4(),
          type: 'options',
          label: this.$t('试驾情况'),
          multiple: false,
          field: 'driveTimes',
          options: [
            { id: uuidv4(), label: this.$t('否'), value: 0 },
            { id: uuidv4(), label: this.$t('1次'), value: 1 },
            { id: uuidv4(), label: this.$t('多次'), value: 2 },
          ],
        })
        arr.push({
          id: uuidv4(),
          type: 'options',
          label: this.$t('购车意向等级'),
          multiple: true,
          field: 'leadLevels',
          options: [
            { id: uuidv4(), label: 'H', value: 'H' },
            { id: uuidv4(), label: 'A', value: 'A' },
            { id: uuidv4(), label: 'B', value: 'B' },
            { id: uuidv4(), label: 'C', value: 'C' },
            { id: uuidv4(), label: 'U', value: 'U' },
            { id: uuidv4(), label: '未评级', value: '' },
          ],
        })
        let tryDriveLevelsOptions = []
        this.$store.getters.getDictHash(1012).forEach((element) => {
          if (element.code != 1013001) {
            tryDriveLevelsOptions.push({
              id: element.code,
              label: element.name,
              value: element.code,
            })
          }
        })
        tryDriveLevelsOptions.push({ id: uuidv4(), label: '未评级', value: '' })
        arr.push({
          id: uuidv4(),
          type: 'options',
          label: this.$t('试驾意向等级'),
          multiple: true,
          field: 'tryDriveLevels',
          options: tryDriveLevelsOptions,
        })
        if (this.type == 'clueDefeat') {
          arr.push({
            id: uuidv4(),
            type: 'options',
            label: this.$t('是否长期战败'),
            field: 'defeatTimes',
            options: [
              { id: uuidv4(), label: this.$t('是'), value: 2 },
              { id: uuidv4(), label: this.$t('否'), value: 0 },
            ],
          })
          arr.push({
            id: uuidv4(),
            type: 'options',
            label: this.$t('客户不愿被联系'),
            field: 'customerNoChat',
            options: [
              { id: uuidv4(), label: this.$t('是'), value: 1 },
              { id: uuidv4(), label: this.$t('否'), value: 0 },
            ],
          })
        }
        arr.push({
          id: uuidv4(),
          type: 'channels',
          label: this.$t('来源渠道'),
          field: 'channels',
          tagsInput: true,
          fields: ['channelOneId', 'channelTwoId', 'channelThreeId', 'channelFourId', 'channelFiveId'],
        })
      }
      this.filterOptions = arr
    },
    onSearch() {
      this.commonFilterParams.searchStr = this.searchStr
    },
    onFilter(params) {
      const obj = {}
      Object.keys(params).forEach((e) => {
        const v = params[e]
        if (!v) return
        if (['reviewStatuses', 'salesUserIds', 'failCodes', 'channelOneId', 'channelTwoId', 'channelThreeId', 'channelFourId', 'channelFiveId'].includes(e)) {
          v.length && (obj[e] = v.map((item) => item.value || item))
        } else if (['leadLevels', 'tryDriveLevels'].includes(e)) {
          v.length && (obj[e] = v.map((item) => item.value))
        } else if (['applyTimeStart', 'reviewTimeStart'].includes(e)) {
          obj[e] = this.$moment(v).format('YYYY-MM-DD 00:00:00')
        } else if (['applyTimeEnd', 'reviewTimeEnd'].includes(e)) {
          obj[e] = this.$moment(v).format('YYYY-MM-DD 23:59:59')
        } else if (['essentialRefundReasons'].includes(e)) {
          obj[e] = v
        } else {
          obj[e] = v.value
        }
      })
      const { searchStr, queryType, transferType } = this.commonFilterParams
      this.searchActive = !!Object.keys(obj).length
      this.$set(this, 'commonFilterParams', {
        ...obj,
        searchStr,
        queryType,
        transferType,
      })
      this.filterPanelVisible = false
    },
    async reviewCenterCount() {
      if (this.$store.getters.userInfo.type == 1) {
        const res = await reviewCenterCount({
          roleCode: this.$route.query.roleCode || '',
        })
        this.countInfo = res
      } else {
        this.countInfo = await reviewCenterCountForProtal({})
      }
      if (!this.type) {
        // 更新上门试驾数量
        const obj = this.tabs.find(
          ({ countKey }) => !!this.countInfo[countKey]
        )
        const type = obj ? obj.componentName : this.tabs[0].componentName
        if (
          this.$store.getters.userInfo.type == 0 &&
          ['leadChangeInto', 'orderEdit'].includes(type)
        ) {
          this.commonFilterParams.transferType = 1
        } else {
          this.commonFilterParams.transferType = ''
        }
        this.type = type
        this.createFilterOptions()
      } else {
        // this.$refs.listRef.onRefresh()
      }
    },
    onChangeQueryType() {
      this.reviewCenterCount()
      this.createFilterOptions()
      this.onFilter({})
    },
    changeTab(item) {
      this.reviewCenterCount()
      this.type = item.componentName
      if (
        this.$store.getters.userInfo.type == 0 &&
        ['leadChangeInto', 'orderEdit'].includes(item.componentName)
      ) {
        this.commonFilterParams.transferType =
          this.commonFilterParams.transferType || 1
      } else {
        this.commonFilterParams.transferType = ''
      }
      this.onFilter({})
      this.createFilterOptions()
    },
    subTabChange(val) {
      this.commonFilterParams.transferType = val
    },
  },
}
</script>

<template>
  <div class="approveManage">
    <div class="approveManage-l">
      <div
        v-for="(item, index) in tabs"
        :key="index"
        :class="{ current: type === item.componentName }"
        @click="changeTab(item)"
      >
        <p>{{ item.name }}</p>
        <p>{{ `(${countInfo[item.countKey] || 0})` }}</p>
      </div>
    </div>
    <div class="approveManage-r">
      <div class="search-wrap">
        <search-bar-new
          :placeholder-text="$t('请输入客户姓名/电话查询')"
          :showScreen="true"
          :active="searchActive"
          @input="
            (val) => {
              searchStr = val;
            }
          "
          @search="onSearch"
          @screening="filterPanelVisible = true"
        >
        </search-bar-new>
        <FilterPanel
          ref="filterPanel"
          v-model="filterPanelVisible"
          :options="filterOptions"
          @submit="onFilter"
        />
        <van-tabs
          v-if="type"
          v-model="commonFilterParams.queryType"
          @change="onChangeQueryType"
        >
          <van-tab title="待审批"></van-tab>
          <van-tab title="已审批"></van-tab>
        </van-tabs>
      </div>
      <div
        v-if="
          $store.getters.userInfo.type == 0 &&
            ['leadChangeInto', 'orderEdit'].includes(type)
        "
      >
        <OptionsBox
          :optionsList="[
            {
              text: type == 'leadChangeInto' ? '转入方审批' : '同区审批',
              value: 1,
            },
            {
              text: type == 'leadChangeInto' ? '转出方审批' : '跨区审批',
              value: 2,
            },
          ]"
          :showCount="false"
          :subType="commonFilterParams.transferType"
          style="padding-bottom: 0px;"
          @change="subTabChange"
        >
        </OptionsBox>
      </div>
      <component
        :is="type"
        v-if="type"
        ref="listRef"
        :commonFilterParams="commonFilterParams"
        class="list-wrap"
        @refresh="reviewCenterCount"
      />
    </div>
  </div>
</template>
<style lang="less" scoped>
.approveManage {
  height: 10vh;
  display: flex;
  .approveManage-l {
    overflow-y: auto;
    font-size: 14px;
    text-align: center;
    > div {
      cursor: pointer;
      padding: 10px 16px;
      max-width: 4em;
      box-sizing: content-box;
      &.current {
        color: #b9921a;
        // background-color: #fff
      }
    }
  }
  .approveManage-r {
    position: relative;
    flex: 1;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    .search-wrap {
    }
    .list-wrap {
      flex: 1;
      overflow-y: auto;
    }
  }
}
</style>

<template>
  <div class="search-bar">
    <form action="#" class="search-input-container">
      <van-search
        v-model.trim="searchStr"
        :maxlength="maxlength"
        clearable
        class="search-input"
        :placeholder="placeholderText"
        :formatter="(value)=> value.replace(/\s+/g, '')"
        format-trigger="onBlur"
        @input="onInput"
        @search="onSearch"
        @clear="onSearch"
      />
    </form>
    <div class="filter-wrap">
      <template v-if="showScreen">
        <slot>
          <img class="img" :src="active ? filterActiveIcon : filterIcon" @click="onScreening"/>
        </slot>
      </template>
      <div v-if="showCategory && getMenu" v-btn-premission="['BTN-CLUE-DISTRIBUTE', 'BTN-CLUE-CREAT', 'BTN-ORDER-REDISTRIBUTE','BTN-DELIVEY-REDISTRIBUTE']">
        <van-popover
          v-model="showActionPopover"
          theme="dark"
          trigger="click"
          placement="bottom-end"
          :actions="actions"
          @select="operate">
          <div class="van-popover__content">
            <template v-for="(item,index) in actions" >
              <div :key="index" v-btn-premission="item.premission" class="van-popover__action" @click.stop="operate(item)">
                <div class="van-popover__action-text van-hairline--bottom">{{ item.text }}</div>              
              </div>
            </template>
          </div>
          <template #reference>
            <img class="img" :src="categoryIcon"/>
          </template>
        </van-popover>
      </div>
    </div>
  </div>
</template>

<script>
import filterIcon from '@/images/filter.svg'
import filterActiveIcon from '@/images/filter_active.svg'
import categoryIcon from '@/images/category.svg'
import { set } from 'lodash'
import { mapGetters } from 'vuex'
import { i18n } from '@/main'
export default {
  props: {
    placeholderText: {
      type: String,
      default: i18n.t('请输入')
    },
    value: { type: String },
    str: {
      type: String,
      default: ''
    },
    showScreen: {
      type: Boolean,
      default: false
    },
    showCategory: {
      type: Boolean,
      default: false
    },
    active: {
      type: Boolean,
      default: false
    },
    actions: {
      type: Array,
      default: () => { [] }
    },
    maxlength: {
      type: [String, Number, undefined],
      default: () => undefined
    }
  },
  data() {
    return {
      filterIcon,
      filterActiveIcon,
      categoryIcon,
      searchStr: '',
      showActionPopover: false,
    }
  },
  computed:{
    ...mapGetters({ getMenu:'app/getMenu' }),
  },
  methods: {
    reset() {
      this.searchStr = ''
    },
    onInput() {
      this.$emit('input', this.searchStr)
    },
    onSearch() {
      this.$emit('search', this.searchStr)
    },
    // 搜索面板
    onScreening() {
      this.$emit('screening')
    },
    // 操作面板
    operate(val) {
      this.showActionPopover = false
      setTimeout(() => {
        this.$emit('operation', val)
      })
    }
  }
}
</script>

<style scoped lang="less">
.search-bar {
  position: relative;
  z-index: 20;
  width: 100%;
  background-color: #fff;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .search-input-container {
    flex: 1;
    padding: 0;
    .search-input {
      padding: 0;
      border-radius: 20px;
      .van-search__content {
        background-color: #f5f5f5;
        border-radius: 24px;
      }
      // placeholder字体颜色
      /deep/ input::placeholder {
        color: rgba(13,23,26,0.45);
      }
      // 前缀图标颜色
      /deep/.van-icon-search{
        color: #666;
        font-size: 15px;
      }
      /deep/.van-field__left-icon{
        margin-right: 6px;
      }
    }
  }
  .filter-wrap {
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    img{
      width: 24px;
      margin-right: 14px;
      &:last-of-type{
        margin-right: 0;
      }
    }
    .van-popover__wrapper{
      display: flex;
      img{
        margin-left: 14px;
      }
    }
  }
}
.van-popover__action{
  width: 144px;
}
</style>


<template>
  <div class="vanTabs-component">
    <van-tabs
      ref="Vantabs"
      v-model="active"
      title-active-color="#B9921A" 
      swipe-threshold="1" 
      title-inactive-color="#0D171A"
      line-height="4px"
      :line-width="lineWidth"
      color="#B9921A" @click="change">
      <van-tab v-for="item in tabList" :key="item.value" title-class="tab-title" :name="item.value"
        :title="item.value === 0 || !isTotal || item.hideCount ? `${item.text}` : `${item.text} (${item.total})`"></van-tab>
    </van-tabs>
    <slot name="icon"></slot>
  </div>
</template>
<script>
export default {
  name: 'vanTabsComponent',
  props: {
    queryType: {
      type: [String,Boolean,Number],
      default: () => null,
    },
    // 是否展示汇总
    isTotal: {
      type: Boolean,
      default: () => true
    },
    tabList: {
      type: Array,
      // [{ text: '首次', value: 1, total: '' }],
      default: () => []
    },
    lineWidth: {
      type: String,
      default: () => '40px'
    }
  },
  data() {
    return {
      active: ''
    }
  },
  watch: {
    queryType: {
      handler(val) {
        this.active = val
      },
      deep: true
    }
  },
  mounted() {
  },
  methods: {
    change() {
      this.$emit('change', this.active)
    },
  }
}
</script>
<style scoped lang="less">
.vanTabs-component{
  position: relative;
  /deep/ .van-tabs__nav{
    padding: 0 16px 15px 16px;
  }
  /deep/ .tab-title{
    padding: 0 6px !important;
  }
  /deep/ .van-tabs--line .van-tabs__wrap {
    height: 34px;
  }

  /deep/.van-tab{
    flex: none;
    margin-right: 12px;
  }
}
</style>
